<template> 
  <div class="body">
    <div>
      <v-container >
        <v-toolbar flat dense>
          <v-spacer />
          <c-date-picker  class="mr-2"
          append-icon="mdi-calendar"
          :label="$t('plannerCodeRange.fromDate')"
          dense outlined  hide-details="auto"
          @input="getPlannerCode()"
          v-model="plannerCodeRange.fromDate">
          </c-date-picker>
          <c-date-picker
          class="mr-2"
          append-icon="mdi-calendar"
          :label="$t('plannerCodeRange.toDate')"
          dense
          outlined
          :max="monthRestriction"
          @input="getPlannerCode()"
          v-model="plannerCodeRange.toDate"
          hide-details="auto"
          ></c-date-picker>
          <v-autocomplete
          v-if="user.User_Category == 'admin' || user.User_Category == 'GC Admin'" 
          outlined
          hide-details="auto"
          dense
          :items="SalesOfficeArr"
          :loading="SalesLoading"
          style="max-width: 25%"
          v-model="SO"
          :label="$t('PlannersInfo.SalesOfficeName')"
          item-text="SalesOfficeData"
          item-code="SalesOfficeData"
          return-object
          @change="getPlannerData()">
          </v-autocomplete>
           <v-autocomplete
            class="mr-2"
            outlined
            maxlength="5"
            dense
            :loading="plannersLoading"
            :items="planners"
            hide-details="auto"
            item-text="EmployeesData"
            item-code="EmployeesData"
           :label="$t('plannerCodeRange.PlannerCode')"
            v-model="plannerCodeRange.Planner"
            style="max-width: 20%"
            return-object 
            @change="cadData.length > 0 ? specificPlanner(plannerCodeRange) : ''"
            >
          </v-autocomplete>
          <v-btn
            class="mr-5"
            @click="specificPlanner(plannerCodeRange)"
            xxl
            :style="{ color: 'white' }"
          :disabled="loading || !plannerCodeRange.Planner.EmployeesData"
            Length="5"
            color="#0D47A1"
            >{{$t(`generate.generate`)}}</v-btn
          >
          <v-btn
            v-if="cadData.length > 0"
            @click="printCSV()"
            xxl
            :style="{ color: 'white' }"
            color="#0D47A1"
            >{{$t(`generate.generateCSV`)}}</v-btn
          >
          <v-spacer/>
        </v-toolbar>
      </v-container>
    </div> 
    <div class="pr-6 pl-6">
      <v-card flat v-if="cadData.length > 0">
        <v-simple-table dense>
          <thead>
            <tr>
              <th colspan="4"
                style="background-color: #01579b; color: white"
                class="text-center TableStyle"
              >
               {{ $t(`PlannersInfo.SalesOfficeName`)}}
              </th>
              <th colspan="4"
                style="background-color: #01579b; color: white"
                class="text-center TableStyle"
              >
              {{ $t(`PlannersInfo.BranchName`)}}
              </th>
              <th colspan="4"
                style="background-color: #01579b; color: white"
                class="text-center TableStyle"
              >
              {{ $t(`PlannersInfo.PlannersName`)}}
              </th>
            </tr>
          </thead>
          <tbody style="background-color: #e8eaf6">
            <tr >
              <td  colspan="4" class="text-center TableStyle">{{ EmpInfo.SalesOfficeName }}</td>
              <td  colspan="4" class="text-center TableStyle">{{ EmpInfo.BranchName }}</td>
              <td  colspan="4" class="text-center TableStyle">
                {{ EmpInfo.EmployeeName }}
              </td>
            </tr>
          </tbody>
        </v-simple-table >
      </v-card>
    </div>
    <div v-if="cadData.length > 0" class="pl-6 pr-6">
      <v-card class="mt-2" flat>  
        <v-simple-table id="tableRef" :height="plannerData.tableHeight" dense :style="{'borderBottom': plannerData.scroll? '1px solid black' : ''}">
            <thead id="tHead1" class="sticky-thead" ref="firstThead" style="top: 0px;">
            <tr>
              <th class="text-center header TableStyle;" colspan="2" rowspan="2"><h3>{{$t(`header.Category`)}}</h3></th>
              <th class="text-center header TableStyle" colspan="4">{{$t(`header.No of CAD`)}}</th>
              <th :style="{ width: plannerData.columnWidth.width1, minWidth: plannerData.columnWidth.width1}" class="text-center header TableStyle" rowspan="1" colspan="2">{{$t(`header.Denki Plan`)}}</th>
              <th :style="{ width: plannerData.columnWidth.width1, minWidth: plannerData.columnWidth.width1}" class="text-center header TableStyle" rowspan="2">{{$t(`header.Color Perspective`)}}</th>
              <th :style="{ width: plannerData.columnWidth.width1, minWidth: plannerData.columnWidth.width1}" class="text-center header TableStyle" rowspan="2">{{'HP'}}</th>
              <th :style="{ width: plannerData.columnWidth.width1, minWidth: plannerData.columnWidth.width1}" class="text-center header TableStyle" rowspan="2">{{$t(`header.Structural Investigation(Waku)`)}}</th>
              <th :style="{ width: plannerData.columnWidth.width1, minWidth: plannerData.columnWidth.width1}" class="text-center header TableStyle" rowspan="2">{{$t(`header.Structural Investigation(Jiku)`)}}</th>
              <th :style="{ width: plannerData.columnWidth.width1, minWidth: plannerData.columnWidth.width1}" class="text-center header TableStyle" rowspan="2">{{'ALVS'}}</th>
            </tr>
            <tr>
              <th :style="{ width: plannerData.columnWidth.width1, minWidth: plannerData.columnWidth.width1}" class="text-center header TableStyle">{{$t(`header.Total`)}}</th>
              <th :style="{ width: plannerData.columnWidth.width1, minWidth: plannerData.columnWidth.width1}" class="text-center header TableStyle">{{$t(`header.CADPDR`)}}</th>
              <th :style="{ width: plannerData.columnWidth.width1, minWidth: plannerData.columnWidth.width1}" class="text-center header TableStyle">{{$t(`header.CADFinal`)}}</th>
              <th :style="{ width: plannerData.columnWidth.width1, minWidth: plannerData.columnWidth.width1}" class="text-center header TableStyle">{{$t(`header.CADExpress`)}}</th>
              <th :style="{ width: plannerData.columnWidth.width1, minWidth: plannerData.columnWidth.width1}" class="text-center header TableStyle">{{$t(`header.Total`)}}</th>
              <th :style="{ width: plannerData.columnWidth.width1, minWidth: plannerData.columnWidth.width1}" class="text-center header TableStyle">{{$t(`header.DenkiExpress`)}}</th>
            </tr>
          </thead>
          <thead id="tHead2" class="sticky-thead" :style="{ top: secondTheadTop + 'px' }" ref="secondThead">
            <tr v-for="(item,i) in average" :key="i">
            <template v-if="i != 1 && i !=2" >
              <th class="text-center header TableStyle" 
                colspan="2"
                ><h3>{{ $t(`Average.${item.title}`)}}</h3>
              </th>
              <th class="text-center TableStyle" :style="{ 'color' : item.cad > 100 && i==6 ? 'red' : ''}"
              >
                <v-tooltip top color="primary" style="z-index: 3">
                  <template v-slot:activator="{ on, attrs }">
                    <h4 v-bind="attrs" v-on="on">{{ `${i==2 ? item.cad+ '/'+branchLength.length : i==4 ?  item.cad+ '/'+salesLength.length : i==6 ?  item.cad+ '/'+NationLength.length : item.cad }`}}</h4>
                  </template>
                  <span v-if="i==0"><b>{{$t(`tooltip.Category`)}}:</b> {{$t(`tooltip.Planner`)}}<br><b>{{$t(`tooltip.Formula`)}}:</b> {{$t(`tooltip.Total CAD Request`)}} / {{$t(`tooltip.Total Kakou-irai`)}}</span>
                  <span v-if="i==3"><b>{{$t(`tooltip.Category`)}}:</b> {{$t(`tooltip.Sales Office`)}}<br><b>{{$t(`tooltip.Formula`)}}:</b> {{$t(`tooltip.Total CAD Request`)}} / {{$t(`tooltip.Total Kakou-irai`)}}</span>
                  <span v-if="i==4">{{$t(`tooltip.Current Rank in Sales Office`)}}</span>
                  <span v-if="i==5"><b>{{$t(`tooltip.Category`)}}:</b> {{$t(`tooltip.Nationwide`)}}<br><b>{{$t(`tooltip.Formula`)}}:</b> {{$t(`tooltip.Total CAD Request`)}} / {{$t(`tooltip.Total Kakou-irai`)}}</span>
                  <span v-if="i==6">{{$t(`tooltip.Current Rank in Nationwide`)}}</span>
                </v-tooltip>
              </th>
              <th
                class="text-center TableStyle" 
                :style="{ 'color' : item.CadPdr > 100 && i==6 ? 'red' : ''}"
              >

              <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <h4 v-bind="attrs" v-on="on">{{ `${i==2 ? item.CadPdr+ '/'+branchLength.length : i==4 ?  item.CadPdr+ '/'+salesLength.length : i==6 ?  item.CadPdr+ '/'+NationLength.length : item.CadPdr }`}}</h4>
                  </template>
                  <span v-if="i==4">{{$t(`tooltip.Current Rank in Sales Office`)}}</span>
                  <span v-if="i==6">{{$t(`tooltip.Current Rank in Nationwide`)}}</span>
                </v-tooltip>
              </th>
              <th
                class="text-center TableStyle" 
                :style="{ 'color' : item.CadFinalPlan > 100 && i==6 ? 'red' : ''}"
              >
              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <h4 v-bind="attrs" v-on="on">{{ `${i==2 ? item.CadFinalPlan+ '/'+branchLength.length : i==4 ?  item.CadFinalPlan+ '/'+salesLength.length : i==6 ?  item.CadFinalPlan+ '/'+NationLength.length : item.CadFinalPlan }`}}</h4>
                </template>
                <span v-if="i==4">{{$t(`tooltip.Current Rank in Sales Office`)}}</span>
                <span v-if="i==6">{{$t(`tooltip.Current Rank in Nationwide`)}}</span>
              </v-tooltip>
              </th>
              <th
                class="text-center TableStyle" 
                :style="{ 'color' : item.CadExpress > 100 && i==6 ? 'red' : ''}"
              >
                <v-tooltip top color="primary" style="z-index: 3">
                  <template v-slot:activator="{ on, attrs }">
                    <h4 v-bind="attrs" v-on="on">{{ `${i==2 ? item.CadExpress+ '/'+branchLength.length : i==4 ?  item.CadExpress+ '/'+salesLength.length : i==6 ?  item.CadExpress+ '/'+NationLength.length : item.CadExpress }`}}</h4>
                  </template>
                  <span v-if="i==4">{{$t(`tooltip.Current Rank in Sales Office`)}}</span>
                  <span v-if="i==6">{{$t(`tooltip.Current Rank in Nationwide`)}}</span>
                </v-tooltip>
              </th>
              <th 
                class="text-center TableStyle" 
                :style="{ 'color' : item.denki > 100 && i==6 ? 'red' : ''}"
              >
                <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <h4 v-bind="attrs" v-on="on">{{ `${i==2 ? item.denki+ '/'+branchLength.length : i==4 ?  item.denki+ '/'+salesLength.length : i==6 ?  item.denki+ '/'+NationLength.length : item.denki }`}}</h4>
                  </template>
                  <span v-if="i==0"><b>{{$t(`tooltip.Category`)}}:</b> {{$t(`tooltip.Planner`)}}<br><b>{{$t(`tooltip.Formula`)}}:</b> {{$t(`tooltip.Total Denki Request`)}} / {{$t(`tooltip.Total Kakou-irai`)}}</span>
                  <span v-if="i==3"><b>{{$t(`tooltip.Category`)}}:</b> {{$t(`tooltip.Sales Office`)}}<br><b>{{$t(`tooltip.Formula`)}}:</b> {{$t(`tooltip.Total Denki Request`)}} / {{$t(`tooltip.Total Kakou-irai`)}}</span>
                  <span v-if="i==4">{{$t(`tooltip.Current Rank in Sales Office`)}}</span>
                  <span v-if="i==5"><b>{{$t(`tooltip.Category`)}}:</b> {{$t(`tooltip.Nationwide`)}}<br><b>{{$t(`tooltip.Formula`)}}:</b> {{$t(`tooltip.Total Denki Request`)}} / {{$t(`tooltip.Total Kakou-irai`)}}</span>
                  <span v-if="i==6">{{$t(`tooltip.Current Rank in Nationwide`)}}</span>
                </v-tooltip>
              </th>
              <th 
                class="text-center TableStyle" 
                :style="{ 'color' : item.denkiExpress > 100 && i==6 ? 'red' : ''}"
              >
                <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <h4 v-bind="attrs" v-on="on">{{ `${i==2 ? item.denkiExpress+ '/'+branchLength.length : i==4 ?  item.denkiExpress+ '/'+salesLength.length : i==6 ?  item.denkiExpress+ '/'+NationLength.length : item.denkiExpress }`}}</h4>
                  </template>
                  <span v-if="i==0"><b>{{$t(`tooltip.Category`)}}:</b> {{$t(`tooltip.Planner`)}}<br><b>{{$t(`tooltip.Formula`)}}:</b> {{$t(`tooltip.Total Denki Request`)}} / {{$t(`tooltip.Total Kakou-irai`)}}</span>
                  <span v-if="i==3"><b>{{$t(`tooltip.Category`)}}:</b> {{$t(`tooltip.Sales Office`)}}<br><b>{{$t(`tooltip.Formula`)}}:</b> {{$t(`tooltip.Total Denki Request`)}} / {{$t(`tooltip.Total Kakou-irai`)}}</span>
                  <span v-if="i==4">{{$t(`tooltip.Current Rank in Sales Office`)}}</span>
                  <span v-if="i==5"><b>{{$t(`tooltip.Category`)}}:</b> {{$t(`tooltip.Nationwide`)}}<br><b>{{$t(`tooltip.Formula`)}}:</b> {{$t(`tooltip.Total Denki Request`)}} / {{$t(`tooltip.Total Kakou-irai`)}}</span>
                  <span v-if="i==6">{{$t(`tooltip.Current Rank in Nationwide`)}}</span>
                </v-tooltip>
              </th>
              <th class="text-center TableStyle" 
                :style="{ 'color' : item.externalPers > 100 && i==6 ? 'red' : ''}"
              >
                <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <h4 v-bind="attrs" v-on="on">{{ `${i==2 ? item.externalPers+ '/'+branchLength.length : i==4 ?  item.externalPers+ '/'+salesLength.length : i==6 ?  item.externalPers+ '/'+NationLength.length : item.externalPers }`}}</h4>
                  </template>
                  <span v-if="i==0"><b>{{$t(`tooltip.Category`)}}:</b> {{$t(`tooltip.Planner`)}}<br><b>{{$t(`tooltip.Formula`)}}:</b> {{$t(`tooltip.Total Color Perspective Request`)}} / {{$t(`tooltip.Total Kakou-irai`)}}</span>
                  <span v-if="i==3"><b>{{$t(`tooltip.Category`)}}:</b> {{$t(`tooltip.Sales Office`)}}<br><b>{{$t(`tooltip.Formula`)}}:</b> {{$t(`tooltip.Total Color Perspective Request`)}} / {{$t(`tooltip.Total Kakou-irai`)}}</span>
                  <span v-if="i==4">{{$t(`tooltip.Current Rank in Sales Office`)}}</span>
                  <span v-if="i==5"><b>{{$t(`tooltip.Category`)}}:</b> {{$t(`tooltip.Nationwide`)}}<br><b>{{$t(`tooltip.Formula`)}}:</b> {{$t(`tooltip.Total Color Perspective Request`)}} / {{$t(`tooltip.Total Kakou-irai`)}}</span>
                  <span v-if="i==6">{{$t(`tooltip.Current Rank in Nationwide`)}}</span>
                </v-tooltip>
              </th>
              <th class="text-center TableStyle" 
                :style="{ 'color' : item.HP > 100 && i==6 ? 'red' : ''}"
              >
                <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <h4 v-bind="attrs" v-on="on">{{ `${i==2 ? item.HP+ '/'+branchLength.length : i==4 ?  item.HP+ '/'+salesLength.length : i==6 ?  item.HP+ '/'+NationLength.length : item.HP }`}}</h4>
                  </template>
                  <span v-if="i==0"><b>{{$t(`tooltip.Category`)}}:</b> {{$t(`tooltip.Planner`)}}<br><b>{{$t(`tooltip.Formula`)}}:</b> {{$t(`tooltip.Total HP Request`)}} / {{$t(`tooltip.Total Kakou-irai`)}}</span>
                  <span v-if="i==3"><b>{{$t(`tooltip.Category`)}}:</b> {{$t(`tooltip.Sales Office`)}}<br><b>{{$t(`tooltip.Formula`)}}:</b> {{$t(`tooltip.Total HP Request`)}} / {{$t(`tooltip.Total Kakou-irai`)}}</span>
                  <span v-if="i==4">{{$t(`tooltip.Current Rank in Sales Office`)}}</span>
                  <span v-if="i==5"><b>{{$t(`tooltip.Category`)}}:</b> {{$t(`tooltip.Nationwide`)}}<br><b>{{$t(`tooltip.Formula`)}}:</b> {{$t(`tooltip.Total HP Request`)}} / {{$t(`tooltip.Total Kakou-irai`)}}</span>
                  <span v-if="i==6">{{$t(`tooltip.Current Rank in Nationwide`)}}</span>
                </v-tooltip>
              </th>
              <th class="text-center TableStyle" 
                :style="{ 'color' : item.siWaku > 100 && i==6 ? 'red' : ''}"
              >
                <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <h4 v-bind="attrs" v-on="on">{{ `${i==2 ? item.siWaku+ '/'+branchLength.length : i==4 ?  item.siWaku+ '/'+salesLength.length : i==6 ?  item.siWaku+ '/'+NationLength.length : item.siWaku }`}}</h4>
                  </template>
                  <span v-if="i==0"><b>{{$t(`tooltip.Category`)}}:</b> {{$t(`tooltip.Planner`)}}<br><b>{{$t(`tooltip.Formula`)}}:</b> {{$t(`tooltip.Total SI(Waku) Request`)}}  / {{$t(`tooltip.Total count SI(Waku) Request`)}}</span>
                  <span v-if="i==3"><b>{{$t(`tooltip.Category`)}}:</b> {{$t(`tooltip.Sales Office`)}}<br><b>{{$t(`tooltip.Formula`)}}:</b> {{$t(`tooltip.Total SI(Waku) Request`)}}  / {{$t(`tooltip.Total count SI(Waku) Request`)}}</span>
                  <span v-if="i==4">{{$t(`tooltip.Current Rank in Sales Office`)}}</span>
                  <span v-if="i==5"><b>{{$t(`tooltip.Category`)}}:</b> {{$t(`tooltip.Nationwide`)}}<br><b>{{$t(`tooltip.Formula`)}}:</b> {{$t(`tooltip.Total SI(Waku) Request`)}}  / {{$t(`tooltip.Total count SI(Waku) Request`)}}</span>
                  <span v-if="i==6">{{$t(`tooltip.Current Rank in Nationwide`)}}</span>
                </v-tooltip>
              </th>
              <th class="text-center TableStyle" 
                :style="{ 'color' : item.siJiku > 100 && i==6 ? 'red' : ''}"
              >
              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <h4 v-bind="attrs" v-on="on">{{ `${i==2 ? item.siJiku+ '/'+branchLength.length : i==4 ?  item.siJiku+ '/'+salesLength.length : i==6 ?  item.siJiku+ '/'+NationLength.length : item.siJiku == 'NaN'? '0' : item.siJiku }`}}</h4>
                </template>
                <span v-if="i==0"><b>{{$t(`tooltip.Category`)}}:</b> {{$t(`tooltip.Planner`)}}<br><b>{{$t(`tooltip.Formula`)}}:</b> {{$t(`tooltip.Total SI(Jiku) Request`)}} / {{$t(`tooltip.Total count SI(Jiku) Request`)}}</span>
                <span v-if="i==3"><b>{{$t(`tooltip.Category`)}}:</b> {{$t(`tooltip.Sales Office`)}}<br><b>{{$t(`tooltip.Formula`)}}:</b> {{$t(`tooltip.Total SI(Jiku) Request`)}} / {{$t(`tooltip.Total count SI(Jiku) Request`)}}</span>
                <span v-if="i==4">{{$t(`tooltip.Current Rank in Sales Office`)}}</span>
                <span v-if="i==5"><b>{{$t(`tooltip.Category`)}}:</b> {{$t(`tooltip.Nationwide`)}}<br><b>{{$t(`tooltip.Formula`)}}:</b> {{$t(`tooltip.Total SI(Jiku) Request`)}} / {{$t(`tooltip.Total count SI(Jiku) Request`)}}</span>
                <span v-if="i==6">{{$t(`tooltip.Current Rank in Nationwide`)}}</span>
              </v-tooltip>
              </th>
              <th class="text-center TableStyle" 
                :style="{ 'color' : item.alvs > 100 && i==6 ? 'red' : ''}"
              >
              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <h4 v-bind="attrs" v-on="on">{{ `${i==2 ? item.alvs+ '/'+branchLength.length : i==4 ?  item.alvs+ '/'+salesLength.length : i==6 ?  item.alvs+ '/'+NationLength.length : item.alvs }`}}</h4>
                </template>
                <span v-if="i==0"><b>{{$t(`tooltip.Category`)}}:</b> {{$t(`tooltip.Planner`)}}<br><b>{{$t(`tooltip.Formula`)}}:</b> {{$t(`tooltip.Total ALVS Request`)}} / {{$t(`tooltip.Total Kakou-irai`)}}</span>
                <span v-if="i==3"><b>{{$t(`tooltip.Category`)}}:</b> {{$t(`tooltip.Sales Office`)}}<br><b>{{$t(`tooltip.Formula`)}}:</b> {{$t(`tooltip.Total ALVS Request`)}} / {{$t(`tooltip.Total Kakou-irai`)}}</span>
                <span v-if="i==4">{{$t(`tooltip.Current Rank in Sales Office`)}}</span>
                <span v-if="i==5"><b>{{$t(`tooltip.Category`)}}:</b> {{$t(`tooltip.Nationwide`)}}<br><b>{{$t(`tooltip.Formula`)}}:</b> {{$t(`tooltip.Total ALVS Request`)}} / {{$t(`tooltip.Total Kakou-irai`)}}</span>
                <span v-if="i==6">{{$t(`tooltip.Current Rank in Nationwide`)}}</span>
              </v-tooltip>
              </th>
            </template>
            </tr>
            <tr>
              <th colspan="13" style="height: 10px"></th>
            </tr>
            <tr>
              <th
                class="text-center header TableStyle"
                colspan="2"
              >
                <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on">
                      <h2>  {{  $t(`header.NoofKAkou`) }} : {{cadData.length}}</h2>
                    </span>
                  </template>
                    <span> {{cadData.length}}</span>
                </v-tooltip>
              </th>
              <th
                class="text-center header TableStyle" 
                colspan="11"
              >{{$t(`header.No of Request`)}}
              </th>
            </tr>
            <tr>
              <th
                class="text-center header TableStyle" 
                rowspan="2"
              >{{$t(`header.CustomerCode`)}}
              </th>
              <th
                class="text-center header TableStyle" 
                rowspan="2"
              >{{$t(`header.BuildingType`)}}
              </th>
              <th
                class="text-center header TableStyle" 
                colspan="4"
              >{{$t('header.No of CAD')}}
              </th>
              <th 
                class="text-center header TableStyle" 
                rowspan="1"
                colspan="2"
              >{{$t(`header.Denki Plan`)}}
              </th>
              <th 
                class="text-center header TableStyle pa-1" 
                rowspan="2"
              >{{$t(`header.Color Perspective`)}}
              </th>
              <th 
                class="text-center header TableStyle" 
                rowspan="2"
              >{{ 'HP' }}
              </th>
              <th 
                class="text-center header TableStyle pl-2 pr-0" 
                rowspan="2"
              >{{$t(`header.Structural Investigation(Waku)`)}}
              </th>
              <th 
                class="text-center header TableStyle pl-2 pr-0" 
                rowspan="2"
              >{{$t(`header.Structural Investigation(Jiku)`)}}
              </th>
              <th 
                class="text-center header TableStyle" 
                rowspan="2"
              >{{ 'ALVS' }}
              </th>
            </tr>
            <tr>
              <th 
                class="text-center header TableStyle" 
              >{{$t(`header.Total`)}}
              </th>
              <th 
                class="text-center header TableStyle pa-0" 
              >{{$t(`header.CADPDR`)}}
              </th>
              <th 
                class="text-center header TableStyle pa-0" 
              >{{$t(`header.CADFinal`)}}
              </th>
              <th 
                class="text-center header TableStyle pa-0" 
              >{{$t(`header.CADExpress`)}}
              </th>
              <th 
                class="text-center header TableStyle" 
              >{{$t(`header.Total`)}}
              </th>
              <th 
                class="text-center header TableStyle" 
              >{{$t(`header.DenkiExpress`)}}
              </th>
            </tr>
          </thead>
          <tbody id="tBody">
            <tr v-for="(item,x) in cadData" :key="x">
              <td
                @mouseenter="hoverColumn(true, x)"
                @mouseleave="hoverColumn(false, x)"
                class="text-center TableStyle pr-0 pl-10"
                :style="{ width: plannerData.columnWidth.width2, maxWidth: plannerData.columnWidth.width2}"
              >
                <span :id="'constructionCode-' + x">
                  {{ item.ConstructionCode }}
                </span>
                <v-btn class="mr-1 ml-4" @click="copyText(item.ConstructionCode, x)" x-small icon>
                  <v-fade-transition>
                    <v-icon v-show="copyIcon[x]">{{showText[x]? 'mdi-check': 'mdi-content-copy'}}</v-icon>
                  </v-fade-transition>
                </v-btn>
                <v-tooltip v-model="showText[x]" right transition="slide-x-transition">
                  <template v-slot:activator="{ on }">
                    <span v-on="on"></span>
                  </template>
                  <span>Copied!</span>
                </v-tooltip>
              </td>
              <td 
                class="text-center TableStyle"
                :style="{ width: plannerData.columnWidth.width2, maxWidth: plannerData.columnWidth.width2}"
              >
                <v-tooltip right color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                        v-on="on">
                          {{Array.isArray(splitChar(item.ConstructionTypeName)) ? splitChar(item.ConstructionTypeName)[0] : splitChar(item.ConstructionTypeName)}}
                    </span>
                    <span
                      style="display: block;"
                      v-bind="attrs"
                        v-on="on">
                          {{Array.isArray(splitChar(item.ConstructionTypeName)) ? splitChar(item.ConstructionTypeName)[1] : ''}}
                    </span>
                  </template>
                  <span class="pa-1"> {{item.HouseType}}</span>
                </v-tooltip>
              </td>
              <td class="text-center TableStyle">{{item.cad == 0 ? 0:item.cad}}</td>
              <td class="text-center TableStyle">{{item.CadPdr == 0 ? item.CadDenkiPlan:item.CadPdr}}</td>
              <td class="text-center TableStyle">{{item.CadFinalPlan}}</td>
              <td class="text-center TableStyle">{{item.CadExpress == 0? item.CadExpress = 0: item.CadExpress}}</td>
              <td class="text-center TableStyle">{{item.denki == 0 ? '':item.denki}}</td>
              <td class="text-center TableStyle">{{item.denkiExpress == 0 ? '':item.denkiExpress}}</td>
              <td class="text-center TableStyle">{{`${item.ExternalPers==0 ?'':item.ExternalPers}`}}</td>
              <td class="text-center TableStyle">{{`${item.House_presentation==0 ? '' :item.House_presentation}`}}</td>
              <td class="text-center TableStyle">{{`${item.SI_WAKU_DUCT==0 ? '':item.SI_WAKU_DUCT}`}}</td>
              <td class="text-center TableStyle">{{`${item.SI_WAKU_DUCT != 0 ? item.SI_JIKU == 0 ? '': '': item.SI_JIKU}`}}</td>
              <td class="text-center TableStyle">{{`${item.ALVS == 0 ? '': item.ALVS}`}}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
    </div>
    <div v-if="!loading && cadData.length == 0">
        <Logo />
    </div>
    <div v-else-if="loading" class="center" >
      <v-overlay :value="loading" color="#000000">
        <v-progress-circular
          :width="10"
          color="primary"
          indeterminate
          :size="70"
        >
        </v-progress-circular>
        <div>{{'ローディング中....'}}</div> 
      </v-overlay>
    </div>
    <details-dialog ref="detailsDialog" :dialogData="'CADRequest'" :CADdata="cadData"></details-dialog>
  </div>
</template>
<script>
import CDatePicker from "../components/CDatePicker.vue";
import moment from 'moment';
import Swal from "sweetalert2";
import _ from 'lodash';
import SalesOfficeClass from '../assets/class/salesOffice'
import PlannerCodeClass from '../assets/class/PlannersCode'
import MERGING from "../assets/class/SalesOfficeRanking/merging"
import PlannerData from '../assets/class/PlannerData/PlannerData.js'
import axios from 'axios';
import Logo from "../components/Logo.vue";
  export default {
        components: { CDatePicker, Logo },
      data() {
          return {
              plannerData: new PlannerData(),
              salesOfficefunction: new SalesOfficeClass(),
              plannersCodefunction: new PlannerCodeClass(),
              plannerCodeRange: {
                      fromDate: moment().subtract(365,'days').format('YYYY-MM-DD'),
                      toDate: moment().format('YYYY-MM-DD'),
                      // fromDate:'2021-03-15',
                      // toDate:'2022-03-15',
                      Planner: {},
                  },
              average:[ 
                      {title:'Average Number of Requests',cad:0,CadPhase1:0,CadPdr:0,CadFinalPlan:0,CadExpress:0,denki:0,denkiExpress:0,externalPers:0,HP:0,siWaku:0,siJiku:0,alvs:0},
                      {title:'TENJIJO Average',cad:0,CadPhase1:0,CadPdr:0,CadFinalPlan:0,CadExpress:0,denki:0,denkiExpress:0,externalPers:0,HP:0,siWaku:0,siJiku:0,alvs:0},
                      {title:'TENJIJO Ranking',cad:'',CadPhase1:'',CadPdr:'',CadFinalPlan:'',CadExpress:'',denki:'',denkiExpress:'',externalPers:'',HP:'',siWaku:'',siJiku:'',alvs:''},
                      {title:'EIGYOUSYO Average',cad:0,CadPhase1:0,CadPdr:0,CadFinalPlan:0,CadExpress:0,denki:0,denkiExpress:0,externalPers:0,HP:0,siWaku:0,siJiku:0,alvs:0},
                      {title:'EIGYOUSYO Ranking',cad:'',CadPhase1:'',CadPdr:'',CadFinalPlan:'',CadExpress:'',denki:'',denkiExpress:'',externalPers:'',HP:'',siWaku:'',siJiku:'',alvs:''},
                      {title:'ZENKOKU Average',cad:0,CadPhase1:0,CadPdr:0,CadFinalPlan:0,CadExpress:0,denki:0,denkiExpress:0,externalPers:0,HP:0,siWaku:0,siJiku:0,alvs:0},
                      {title:'ZENKOKU Ranking',cad:'',CadPhase1:'',CadPdr:'',CadFinalPlan:'',CadExpress:'',denki:'',denkiExpress:'',externalPers:'',HP:'',siWaku:'',siJiku:'',alvs:''},
                    ],
              merge: new MERGING(),
              user:{},
              planners:[],
              plannersLoading:false,
              EmpInfo: {},
              cadData:[],
              cadDetails:[],
              testlang:[],
              SO:'',
              SalesOfficeArr:[],
              branchLength:[],
              salesLength:[],
              NationLength:[],
              SalesLoading:false,
              datePicker: {},
              loading:false,
              showText:{},
              copyIcon: [],
              lastCopiedIndex: null,
              secondTheadTop: 0,
          }
      },
      methods: {
          details(){
            this.$refs.detailsDialog.openDialog();
          },
/*ROUNDING NUMBER*/
          tofixed2(num){
            let a = (Math.round((num ) * 10) / 10).toFixed(1)
            if (a == 0.0){
              return a=0
            }else{
              return a
            }
          },

          // tofixed2(num) {
          //   let a = num.toFixed(2);
          //   if (a == 0.0) {
          //     return 0;
          //   } else {
          //     return a.slice(0, -1);
          //   }
          // },
          printCSV(){
            // console.log(this.cadData);
            let test = this.cadData.map(a=>{
              return {
                        ConstructionCode:a.ConstructionCode,
                        HouseType:a.ConstructionTypeName,
                        // ['No of Days of Construction']:a.KakouDays,
                        // ['No of Days of StopCheck']:a.stopcheck,
                        // ['No of Days of FinalPlan']:a.finalPlan,
                        // ['No of Days of Joutou']:a.JoutouDays,
                        CAD:a.cad,
                        // CadPhase1:a.CadPhase1,
                        CadPdr:a.CadPdr,
                        CadFinalPlan:a.CadFinalPlan,
                        CadExpress:a.CadExpress,
                        Denki:a.denki,
                        ['External Pers']:a.ExternalPers,
                        ['House Presentation']: a.House_presentation,
                        ['SI Waku Duct']:a.SI_WAKU_DUCT,
                        ['SI JIku']:a.SI_WAKU_DUCT != 0? a.SI_JIKU = 0: a.SI_JIKU,
                        ALVS:a.ALVS
                    }
            })
            
            let headers = [
              {text: this.$t('header.CustomerCode'), value: "ConstructionCode"},
              {text: this.$t('header.BuildingType'), value: "HouseType"},
              // {text: "Days from ConstructionDate to KAKOU_IRAI", value: "No of Days of Construction"},
              // {text: "Days from First Stopcheck Request to KAKOU_IRAI", value: "No of Days of StopCheck"},
              // {text: "Days from final plan (FP) Request to KAKOU_IRAI", value: "No of Days of FinalPlan"},
              // {text: "Days from Kakou Irai to Joutou", value: "No of Days of Joutou"},
              // { text: "CAD", value: "CAD"},
              { text: this.$t('tooltip.Total CAD Request'), value: "CAD"},
              // {text: this.$t('header.CADPhase1'), value: "CadPhase1"},
              {text: this.$t('header.CADPDR'), value: "CadPdr"},
              {text: this.$t('header.CADFinal'), value: "CadFinalPlan"},
              {text: this.$t('header.CADExpress'), value: "CadExpress"},
              {text: this.$t('header.Denki Plan'), value: "Denki"},
              {text: this.$t('header.Color Perspective'), value: "External Pers"},
              {text: "HP", value: "House Presentation"},
              {text: this.$t('header.Structural Investigation(Waku)'), value: "SI Waku Duct"},
              {text: this.$t('header.Structural Investigation(Jiku)'), value: "SI JIku"},
              {text: "ALVS", value: "ALVS"}
            ]
            let csv = ""
          //header
          // for(let header of headers){
          //   csv += header.text + ","
          // }
          for(let header of headers){
            csv += header.text + ","
          }
          csv+="\n"
          //items
          for(let item of test){
              
            for(let header of headers){
              if(item[header.value] ==null || item[header.value] ==0){
                item[header.value] ="  "
              }
              csv+= item[header.value] + ","
            }
              csv+="\n"
          }
            csv+="\n"
          let filename = "IchijoSekkeiData_CadRequestData-1.csv"

          var BOM = "\uFEFF";
          var   csvData = BOM + csv;
          var blob = new Blob([csvData], { type: 'application/vnd.ms-excel;charset=ISO-2022-JP;' });
            if (navigator.msSaveBlob) { // IE 10+
                navigator.msSaveBlob(blob, filename);
            } 
            else {
                var link = document.createElement("a");
                if (link.download !== undefined) { // feature detection
                    // Browsers that support HTML5 download attribute
                    var url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", filename);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
            //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
             let csv2=""
             let headers2 = [
              {text: this.$t('header.Category')},
              // {text: "CAD", value: "CAD"},
              { text: this.$t('tooltip.Total CAD Request'), value: "CAD"},
              // {text: this.$t('header.CADPhase1'), value: "CadPhase1"},
              {text: this.$t('header.CADPDR'), value: "CadPdr"},
              {text: this.$t('header.CADFinal'), value: "CadFinalPlan"},
              {text: this.$t('header.CADExpress'), value: "CadExpress"},
              {text: this.$t('header.Denki Plan'), value: "Denki"},
              {text: this.$t('header.Color Perspective'), value: "External Pers"},
              {text: "HP", value: "House Presentation"},
              {text: this.$t('header.Structural Investigation(Waku)'), value: "SI Waku Duct"},
              {text: this.$t('header.Structural Investigation(Jiku)'), value: "SI JIku"},
              {text: "ALVS", value: "alvs"}

            ]
              for(let header of headers2){
                csv2 += header.text + ","
              }
                csv2+="\n"
            let summaryHeaders = [
              {text: "title", value: "title"},
              {text: "cad", value: "cad"},
              // {text: "CadPhase1", value: "CadPhase1"},
              {text: "CadPdr", value: "CadPdr"},
              {text: "CadFinalPlan", value: "CadFinalPlan"},
              {text: "CadExpress", value: "CadExpress"},
              {text: "denki", value: "denki"},
              {text: "externalPers", value: "externalPers"},
              {text: "HP", value: "HP"},
              {text: "siWaku", value: "siWaku"},
              {text: "siJiku", value: "siJiku"},
              {text: "ALVS", value: "alvs"},
            ]
            let noRankings = [
              {text: "title", value: "title"}
            ]
            // for(let item of this.average){
          let aveHolder= JSON.parse(JSON.stringify(this.average))
            for(let i=0; i<=aveHolder.length-1;i++){
              // if(i == 1 ){
              //   aveHolder.splice(i,2)
              // }   
              for(let header of summaryHeaders){
                if (header.text == 'title') {
                  const translationKey = `Average.${aveHolder[i].title}`;
                  aveHolder[i][header.value] = this.$t(translationKey);
                }
                if(i == 2 && !noRankings.some(item => item.value === header.value)){
                  aveHolder[i][header.value] = ` ${aveHolder[i][header.value]}/ ${this.salesLength.length}`.replace("'", "");
                }
                if(i == 4 && !noRankings.some(item => item.value === header.value)){
                  aveHolder[i][header.value] =   ` ${aveHolder[i][header.value]}/ ${this.salesLength.length}`.replace("'", "");
                }
                if(i == 6 && !noRankings.some(item => item.value === header.value)){
                  aveHolder[i][header.value] =  ` ${aveHolder[i][header.value]}/ ${this.NationLength.length}`.replace("'", "");
                }
                csv2+= (aveHolder[i][header.value] ? aveHolder[i][header.value] =='NaN' ? '': aveHolder[i][header.value] : '' ) + ","
              }
              csv2+="\n"
            } 
         let filename2 = "IchijoSekkeiData_CadRequestData-2.csv"
         
          var   csvData2 = BOM + csv2;
          var blob2 = new Blob([csvData2], { type: 'application/vnd.ms-excel;charset=ISO-2022-JP;' });
            if (navigator.msSaveBlob) { // IE 10+
                navigator.msSaveBlob(blob2, filename2);
            } 
            else {
                var link2 = document.createElement("a");
                if (link2.download !== undefined) { // feature detection
                    var url2 = URL.createObjectURL(blob2);
                    link2.setAttribute("href", url2);
                    link2.setAttribute("download", filename2);
                    link2.style.visibility = 'hidden';
                    document.body.appendChild(link2);
                    link2.click();
                    document.body.removeChild(link2);
                }
            }
          },
          specificPlanner(item) {

            if(item.Planner.SalesOfficeCode == undefined ){
              Swal.fire({
                      icon: "error",
                      title: "Error!",
                      text: `Planner ${this.userInfo.Emp_code} dont have Data`,
                    });
              return false
            }

              this.datePicker = {
                fromDate:item.fromDate,
                toDate:item.toDate,
                BranchCode:item.Planner.ContractExhibitionCode,
                BranchName:item.Planner.ContractExhibitionName,
                EmployeeCode:item.Planner.EmployeeCode,
                EmployeeName:item.Planner.EmployeeName,
                SalesOfficeCode:item.Planner.SalesOfficeCode,
                SalesOfficeName:item.Planner.SalesOfficeName
              };
                this.loadData();
          },
          test(data){
            let planner=[]
            let test=[]
            data.forEach(x=>{
              let index = planner.findIndex(y=> y.EmployeeCode==x.EmployeeCode)
              if(index==-1){
                planner.push({EmployeeCode:x.EmployeeCode})
              }
            })
            planner.forEach(rec=>{
              let arr=[]
              data.forEach(rec1=>{ 
                if(rec.EmployeeCode == rec1.EmployeeCode){
                  arr.push(rec1)
                }
              })
              test.push(arr)
            })
            return(test)
          },
          async loadData() {
            try {
        if( parseInt(moment.duration(moment(this.plannerCodeRange.toDate,"YYYY-MM-DD").diff(moment(this.plannerCodeRange.fromDate,"YYYY-MM-DD"))).asDays()) <= 365   ){

            this.loading=true
            this.EmpInfo=this.datePicker
            this.cadData=[]
            this.cadDetails=[]
            let branchesData=[]
            let saleData=[]
            this.average=[ 
                    //  {title:'Average Number of Requests',cad:0,denki:0,externalPers:0,HP:0,siWaku:0,siJiku:0,alvs:0},
                     {title:'Average Number of Requests',cad:0,CadPhase1:0,CadPdr:0,CadFinalPlan:0,CadExpress:0,denki:0,denkiExpress:0,externalPers:0,HP:0,siWaku:0,siJiku:0,alvs:0},
                      {title:'TENJIJO Average',cad:0,CadPhase1:0,CadPdr:0,CadFinalPlan:0,CadExpress:0,denki:0,denkiExpress:0,externalPers:0,HP:0,siWaku:0,siJiku:0,alvs:0},
                      {title:'TENJIJO Ranking',cad:'',CadPhase1:'',CadPdr:'',CadFinalPlan:'',CadExpress:'',denki:'',denkiExpress:'',externalPers:'',HP:'',siWaku:'',siJiku:'',alvs:''},
                      {title:'EIGYOUSYO Average',cad:0,CadPhase1:0,CadPdr:0,CadFinalPlan:0,CadExpress:0,denki:0,denkiExpress:0,externalPers:0,HP:0,siWaku:0,siJiku:0,alvs:0},
                      {title:'EIGYOUSYO Ranking',cad:'',CadPhase1:'',CadPdr:'',CadFinalPlan:'',CadExpress:'',denki:'',denkiExpress:'',externalPers:'',HP:'',siWaku:'',siJiku:'',alvs:''},
                      {title:'ZENKOKU Average',cad:0,CadPhase1:0,CadPdr:0,CadFinalPlan:0,CadExpress:0,denki:0,denkiExpress:0,externalPers:0,HP:0,siWaku:0,siJiku:0,alvs:0},
                      {title:'ZENKOKU Ranking',cad:'',CadPhase1:'',CadPdr:'',CadFinalPlan:'',CadExpress:'',denki:'',denkiExpress:'',externalPers:'',HP:'',siWaku:'',siJiku:'',alvs:''},
                    ]
                      // await axios.post(`${this.aws2}getCadDetailsTEST`,this.EmpInfo,this.apiKey2).then(res=>{
                      await axios.post(`${this.aws}getCadDetails`,this.EmpInfo,this.apiKey).then(res=>{
                    // await axios.post(`http://10.11.1.59:4027/test/getCadDetails`,this.EmpInfo).then(async(res)=>{  
                      // console.log(res.data);
                      if(res.data){
                  let newMerge=this.test(res.data)
                  let overall=[{countDocu:res.data,plannerCode:newMerge}]
                  overall[0].countDocu.filter(x=>{
                        if(x.EmployeeCode==`${this.datePicker.EmployeeCode}` ){
                          this.cadDetails.push(x)
                        }
                    })
                  }
                })
              await this.merge.MergeFnc(this.aws,this.EmpInfo,this.apiKey).then(res=>{
              if(res.data){
                // let overall =res.data
                  // let merge=[...res.data,...res2.data]
                  let newMerge=this.test(res.data)
                  let overall=[{countDocu:res.data,plannerCode:newMerge}]
                  let brnch =  this.planners.filter(r=>{
                    if(this.datePicker.BranchCode == r.ContractExhibitionCode){
                        return r
                    }
                  })
            
                  let SlsOFFc = this.planners.filter(r=>{
                    if(this.datePicker.SalesOfficeCode == r.SalesOfficeCode){
                        return r
                    }
                  })
                    overall[0].countDocu.filter(x=>{
                      this.cadDetails.filter(y=>{
                        if(x.EmployeeCode==`${this.datePicker.EmployeeCode}` && 
                          x.EmployeeCode === y.EmployeeCode &&
                          x.ConstructionCode === y.ConstructionCode &&
                          x.ConstructionTypeName === y.ConstructionTypeName &&
                          x.SentProcessRequestDate === y.SentProcessRequestDate
                        ){
                          const mergedObject = { ...x, ...y };
                          this.cadData.push(mergedObject);
                        }
                      })
                        brnch.filter(r=>{
                          if(x.EmployeeCode == r.EmployeeCode){
                            branchesData.push(x)
                          }
                        })
                        SlsOFFc.filter(r=>{
                          if(x.EmployeeCode == r.EmployeeCode){
                            saleData.push(x)
                          }
                        })
                        // this.brnch.forEach(r=>{
                        //     if(this.datePicker.BranchCode == r.AssignedExhibitionCode){
                        //         branchesData.push(x)
                        //         console.log(x,'branch')
                        //     }
                        //   if( x.SalesOfficeCode==r.SalesOfficeCode ){
                        //    saleData.push(x)
                        //   }
                        // })
                    })
                    let jikuLength=[]
                    let wakuLength=[]
                    let alvsLength=[]
                    let denkiExpressLength=[]
              let category = ['CadPhase1','CadPdr','CadFinalPlan','CadExpress','cad','denki','denkiExpress','externalPers','HP','siWaku','siJiku','alvs']
              if(this.cadData.length > 0){
                  for(let i=0;i<=this.cadData.length-1;i++){             
                    this.average[0].cad+=parseInt(this.cadData[i].cad?this.cadData[i].cad:0)
                    this.average[0].denki+=parseInt(this.cadData[i].denki?this.cadData[i].denki:0)
                    this.average[0].externalPers+=parseInt(this.cadData[i].ExternalPers?this.cadData[i].ExternalPers:0)
                    this.average[0].HP+=parseInt(this.cadData[i].House_presentation?this.cadData[i].House_presentation:0)
                  
                  if(this.cadData[i].SI_WAKU_DUCT){
                      this.average[0].siWaku+=parseInt(this.cadData[i].SI_WAKU_DUCT?this.cadData[i].SI_WAKU_DUCT:0)
                      wakuLength.push(this.cadData[i])
                   }
                   if(!this.cadData[i].SI_WAKU_DUCT && this.cadData[i].SI_JIKU){
                    // this.average[0].siJiku+=parseInt(this.cadData[i].SI_JIKU?this.cadData[i].SI_JIKU:0)
                    this.average[0].siJiku+=parseInt(this.cadData[i].SI_WAKU_DUCT == 0 ? this.cadData[i].SI_JIKU : 0)
                    jikuLength.push(this.cadData[i])
                   }
                   if(this.cadData[i].ALVS){
                    this.average[0].alvs+=parseInt(this.cadData[i].ALVS?this.cadData[i].ALVS:0)
                    alvsLength.push(this.cadData[i])
                   }
                   if(this.cadData[i].denkiExpress){
                    this.average[0].denkiExpress += parseInt(this.cadData[i].denkiExpress ?? 0)
                    denkiExpressLength.push(this.cadData[i])
                   }

                    // this.average[0].siJiku+=parseInt(this.cadData[i].SI_JIKU?this.cadData[i].SI_JIKU:0)
                    // jikuLength.push(this.cadData[i])

                    // this.average[0].alvs+=parseInt(this.cadData[i].ALVS?this.cadData[i].ALVS:0)
                    // alvsLength.push(this.cadData[i])
                }
                this.average[0].cad =  this.tofixed2((this.average[0].cad / this.cadData.length))
                this.average[0].denki =  this.tofixed2((this.average[0].denki / this.cadData.length))  
                this.average[0].denkiExpress = isNaN(this.tofixed2((this.average[0].denkiExpress / denkiExpressLength.length))) ? 0 : this.tofixed2((this.average[0].denkiExpress / denkiExpressLength.length))
                this.average[0].externalPers =  this.tofixed2((this.average[0].externalPers / this.cadData.length))  
                this.average[0].HP =  this.tofixed2((this.average[0].HP / this.cadData.length))  
                this.average[0].siWaku =  this.tofixed2((this.average[0].siWaku / wakuLength.length))  
                this.average[0].siJiku =  this.tofixed2((this.average[0].siJiku / jikuLength.length)) 
                this.average[0].alvs =  this.tofixed2((this.average[0].alvs / alvsLength.length)) 
                // this.average[0].alvs =  this.tofixed2((this.average[0].alvs / this.cadData.length)) 
                this.loading=false
              
              const cadPhase1Values = this.cadData.map(data => data.CadPhase1).filter(value => value > 0);
              const totalCadPhase1 = cadPhase1Values.reduce((sum, value) => sum + value, 0);
              const averageCadPhase1 = this.tofixed2((totalCadPhase1 / cadPhase1Values.length));

              const cadPdrValues = this.cadData.map(data => data.CadPdr == 0 ? data.CadDenkiPlan : data.CadPdr).filter(value => value > 0);
              const totalCadPdr = cadPdrValues.reduce((sum, value) => sum + value, 0);
              const averageCadPdr = this.tofixed2((totalCadPdr / cadPdrValues.length));
              
              const cadFinalPlanValues = this.cadData.map(data => data.CadFinalPlan).filter(value => value > 0);
              const totalCadFinalPlan = cadFinalPlanValues.reduce((sum, value) => sum + value, 0);
              const averageCadFinalPlan = this.tofixed2((totalCadFinalPlan / cadFinalPlanValues.length));


              const cadexpressValues = this.cadData.map(data => data.CadExpress).filter(value => value > 0);
              const totalCadExpress = cadexpressValues.reduce((sum, value) => sum + value, 0);
              const averageCadExpress = this.tofixed2((totalCadExpress / cadexpressValues.length));


              this.average[0].CadPhase1 = (isNaN(averageCadPhase1) ? 0 : averageCadPhase1)
              this.average[0].CadPdr = (isNaN(averageCadPdr) ? 0 : averageCadPdr)
              this.average[0].CadFinalPlan = (isNaN(averageCadFinalPlan) ? 0 : averageCadFinalPlan)
              this.average[0].CadExpress = (isNaN(averageCadExpress) ? 0 : averageCadExpress)

                // console.log( this.average[0],'ex[res]')
              }
              else{
                  // Swal.fire({
                  //   icon: "error",
                  //   // title: "Oops...",
                  //   title: `${this.$i18n.locale =='ja'?'エラー' :'Error' }`,
                  //   text: `${this.$i18n.locale =='ja'?'営業所名もしくは設計担当者コードの選択がされておりません。' :'The sales office name or designer code has not been selected!'}`,
                  // });
                  Swal.fire({
                    icon: "error",
                    // title: "Oops...",
                    title: `${this.$i18n.locale =='ja'?'エラー' :'Error' }`,
                    text: `No Plan, No Kakouirai`,
                  });
                  this.loading = false;
                  return false
              }
              // console.log(this.cadData,'cadData');
// branches
let BranchCadLength=[]
let BranchCadPhase1Length=[]
let BranchCadExpressLength=[]
let BranchCadPdrLength=[]
let BranchCadFinalPlanLength=[]
let BranchdenkiLength=[]
let BranchdenkiExpressLength=[]
let BranchexternalPersLength=[]
let BranchHPLength=[]
let BranchwakuLength=[]
let BranchjikuLength=[]
let BranchalvsLength=[]
          for(let x=0;x<=branchesData.length-1;x++){
                  if(branchesData[x].cad){
                    this.average[1].cad+=parseInt(branchesData[x].cad)
                    BranchCadLength.push(branchesData[x].cad)
                  }
                  if(branchesData[x].CadPhase1){
                    this.average[1].CadPhase1+=parseInt(branchesData[x].CadPhase1 ? branchesData[x].CadPhase1:0)
                    BranchCadPhase1Length.push(branchesData[x].CadPhase1)
                  }
                  if(branchesData[x].CadPdr){
                    this.average[1].CadPdr+=parseInt(branchesData[x].CadPdr?branchesData[x].CadPdr:0)
                    BranchCadPdrLength.push(branchesData[x].CadPdr)
                  }
                  if(branchesData[x].CadFinalPlan){
                    this.average[1].CadFinalPlan+=parseInt(branchesData[x].CadFinalPlan?branchesData[x].CadFinalPlan:0)
                    BranchCadFinalPlanLength.push(branchesData[x].CadFinalPlan)
                  }
                  if(branchesData[x].CadExpress){
                    this.average[1].CadExpress+=parseInt(branchesData[x].CadExpress?branchesData[x].CadExpress:0)
                    BranchCadExpressLength.push(branchesData[x].CadExpress)
                  }
                  if(branchesData[x].denki){
                    this.average[1].denki+=parseInt(branchesData[x].denki)
                    BranchdenkiLength.push(branchesData[x].denki)
                  }
                  if(branchesData[x].denkiExpress){
                    this.average[1].denkiExpress+=parseInt(branchesData[x].denkiExpress)
                    BranchdenkiExpressLength.push(branchesData[x].denkiExpress)
                  }
                  if(branchesData[x].ExternalPers){
                    this.average[1].externalPers+=parseInt(branchesData[x].ExternalPers)
                    BranchexternalPersLength.push(branchesData[x].ExternalPers)
                  }
                  if(branchesData[x].House_presentation){
                   this.average[1].HP+=parseInt(branchesData[x].House_presentation)
                   BranchHPLength.push(branchesData[x].House_presentation)
                  }
                  if(branchesData[x].SI_WAKU_DUCT){
                    this.average[1].siWaku+=parseInt(branchesData[x].SI_WAKU_DUCT)
                    BranchwakuLength.push(branchesData[x].SI_WAKU_DUCT)
                  }
                  if(branchesData[x].SI_JIKU){
                    this.average[1].siJiku+=parseInt(branchesData[x].SI_JIKU)
                    BranchjikuLength.push(branchesData[x].SI_JIKU)
                  }
                  if(branchesData[x].ALVS){
                    this.average[1].alvs+=parseInt(branchesData[x].ALVS)
                    BranchalvsLength.push(branchesData[x].ALVS)
                  }
                }
                this.average[1].cad =  this.tofixed2((this.average[1].cad / BranchCadLength.length))
                this.average[1].CadPhase1 =  this.tofixed2((this.average[1].CadPhase1 / BranchCadPhase1Length.length))
                this.average[1].CadPdr =  this.tofixed2((this.average[1].CadPdr / BranchCadPdrLength.length))
                this.average[1].CadFinalPlan =  this.tofixed2((this.average[1].CadFinalPlan / BranchCadFinalPlanLength.length))
                this.average[1].CadExpress =  this.tofixed2((this.average[1].CadExpress / BranchCadExpressLength.length))
                this.average[1].denki =  this.tofixed2((this.average[1].denki / BranchdenkiLength.length))  
                this.average[1].denkiExpress = this.tofixed2((this.average[1].denkiExpress / BranchdenkiExpressLength.length))
                this.average[1].externalPers =  this.tofixed2((this.average[1].externalPers / BranchexternalPersLength.length))  
                this.average[1].HP =  this.tofixed2((this.average[1].HP /BranchHPLength.length))  
                this.average[1].siWaku =  this.tofixed2((this.average[1].siWaku / BranchwakuLength.length))  
                this.average[1].siJiku =  this.tofixed2((this.average[1].siJiku /BranchjikuLength.length))
                this.average[1].alvs =  this.tofixed2((this.average[1].alvs /BranchalvsLength.length))
                let plann=[]
                let plann2=[]
                overall[0].plannerCode.forEach(val => {
                  let a=[]
                  let b=[]
                  val.filter(x=>{
                      brnch.filter(r=>{
                        if(x.EmployeeCode == r.EmployeeCode){
                          a.push(x)
                        }
                      })
                      SlsOFFc.filter(r=>{
                        if(x.EmployeeCode == r.EmployeeCode){
                          b.push(x)
                        }
                      })
                    
                    })
                    if(a.length > 0){
                     plann.push(a)
                    }
                    if(b.length > 0){
                      plann2.push(b)
                    }
                 }); 
              


               let arrt=[]

                  for(let x=0;x<=plann.length-1;x++){
                    let obj={plannCode:'',CadPhase1:0,CadPdr:0,CadFinalPlan:0,CadExpress:0,cad:0,denki:0,denkiExpress:0,externalPers:0,HP:0,siWaku:0,siJiku:0,alvs:0}
                      if(plann[x]){
                        for(let i=0;i<=plann[x].length-1;i++){
                          obj.plannCode=plann[x][0].EmployeeCode
                          obj.CadPhase1+= plann[x][i].CadPhase1 ? parseInt(plann[x][i].CadPhase1) : 0
                          obj.CadPdr+=plann[x][i].CadPdr ? parseInt(plann[x][i].CadPdr)  : 0
                          obj.CadFinalPlan+plann[x][i].CadFinalPlan ? parseInt(plann[x][i].CadFinalPlan)  : 0
                          obj.CadExpress+=plann[x][i].CadExpress ? parseInt(plann[x][i].CadExpress)  : 0
                          obj.cad+=plann[x][i].cad  ? parseInt(plann[x][i].cad) : 0
                          obj.denki+=plann[x][i].denki  ? parseInt(plann[x][i].denki) : 0
                          obj.denkiExpress+=plann[x][i].denkiExpress  ? parseInt(plann[x][i].denkiExpress) : 0
                          obj.externalPers+=plann[x][i].ExternalPers  ? parseInt(plann[x][i].ExternalPers) : 0
                          obj.HP+=plann[x][i].House_presentation  ? parseInt(plann[x][i].House_presentation) : 0
                          obj.siWaku+=plann[x][i].SI_WAKU_DUCT  ? parseInt(plann[x][i].SI_WAKU_DUCT) : 0
                          obj.siJiku+=plann[x][i].SI_JIKU  ? parseInt(plann[x][i].SI_JIKU) : 0
                          obj.alvs+=plann[x][i].ALVS ?  parseInt(plann[x][i].ALVS) : 0
                        }
                        obj.CadPhase1 = parseFloat(this.tofixed2((obj.CadPhase1 / plann2[x].length)))
                        obj.CadPdr = parseFloat(this.tofixed2((obj.CadPdr / plann2[x].length)))
                        obj.CadFinalPlan = parseFloat(this.tofixed2((obj.CadFinalPlan / plann2[x].length)))
                        obj.CadExpress = parseFloat(this.tofixed2((obj.CadExpress / plann2[x].length)))
                        obj.cad = parseFloat(this.tofixed2((obj.cad / plann[x].length)))
                        obj.denki = parseFloat(this.tofixed2((obj.denki / plann[x].length)) ) 
                        obj.denkiExpress = parseFloat(this.tofixed2((obj.denkiExpress / plann[x].length)) ) 
                        obj.externalPers = parseFloat(this.tofixed2((obj.externalPers / plann[x].length)))
                        obj.HP = parseFloat(this.tofixed2((obj.HP /plann[x].length)))
                        obj.siWaku = parseFloat(this.tofixed2((obj.siWaku / plann[x].length)))
                        obj.siJiku = parseFloat(this.tofixed2((obj.siJiku /plann[x].length)))
                        obj.alvs = parseFloat(this.tofixed2((obj.alvs /plann[x].length)))

                    }
                    arrt.push(obj)
                  }
                
                  for(let x =0; x<= category.length -1; x++){
                    this.branchLength=  _.orderBy(arrt ,[`${category[x]}`] ,['asc'])
                    for(let i =0 ; i <= this.branchLength.length-1;i++){
                      if(this.branchLength[i].plannCode==`${this.datePicker.EmployeeCode}`){
                          this.average[2][`${category[x]}`]  = i+1 
                      }
                    }
                    // let arr=this.branchLength.reduce((total,item)=>{
                    //       let index =total.findIndex(y=> y.value == item[`${category[x]}`] ) 
                    //       if(index > -1 ){
                    //           total[index].data.push(item)
                    //         return total
                    //       }
                    //       else{
                    //         total.push(
                    //         {
                    //           value:item[`${category[x]}`],
                    //           data:[item]
                    //         }
                    //         )
                    //         return total
                    //       }
                    //   },[])
                    //   let ctr = 0 
                    //   arr.filter((rec,index)=>{
                            
                    //       rec.data.forEach((r)=>{
                    //         if(r.plannCode == `${this.datePicker.EmployeeCode}` && index == 0){
                    //           this.average[2][`${category[x]}`] = index+1
                    //         }
                    //         else if (r.plannCode == `${this.datePicker.EmployeeCode}` && index > 0){
                    //           this.average[2][`${category[x]}`] = ctr +1
                    //         }
                    //       })
                    //     ctr +=  rec.data.length
                    //   })
                  }
//sales Office    
let CadLength=[]
let CadPhase1Length=[]
let CadExpressLength=[]
let CadPdrLength=[]
let CadFinalPlanLength=[]
let denkiLength=[]
let denkiExpressSalesLength=[]
let externalPersLength=[]
let HPLength=[]
let wakuSalesLength=[]
let jikuSalesLength=[]
let alvsSalesLength=[]
                  for(let x=0;x<=saleData.length-1;x++){

                    // if(saleData[x].cad){
                      // this.average[3].cad+=parseInt(saleData[x].cad?saleData[x].cad:0)
                      // this.average[3].CadPhase1+=parseInt(saleData[x].CadPhase1?saleData[x].CadPhase1:0)
                      // this.average[3].CadPdr+=parseInt(saleData[x].CadPdr?saleData[x].CadPdr:0)
                      // this.average[3].CadFinalPlan+=parseInt(saleData[x].CadFinalPlan?saleData[x].CadFinalPlan:0)
                    // }
                    // if(saleData[x].denki){
                      // this.average[3].denki+=parseInt(saleData[x].denki?saleData[x].denki:0)
                    // }
                    // if(saleData[x].ExternalPers){
                      // this.average[3].externalPers+=parseInt(saleData[x].ExternalPers?saleData[x].ExternalPers:0)
                    // }
                    // if(saleData[x].House_presentation){
                    // this.average[3].HP+=parseInt(saleData[x].House_presentation?saleData[x].House_presentation:0)
                    // }
                    if(saleData[x].cad){
                      this.average[3].cad+=parseInt(saleData[x].cad ? saleData[x].cad:0)
                      CadLength.push(saleData[x].cad)
                    }
                    if(saleData[x].CadPhase1){
                      this.average[3].CadPhase1+=parseInt(saleData[x].CadPhase1 ? saleData[x].CadPhase1:0)
                      CadPhase1Length.push(saleData[x].CadPhase1)
                    }
                    if(saleData[x].CadPdr){
                      this.average[3].CadPdr+=parseInt(saleData[x].CadPdr?saleData[x].CadPdr:0)
                      CadPdrLength.push(saleData[x].CadPdr)
                    }
                    if(saleData[x].CadFinalPlan){
                      this.average[3].CadFinalPlan+=parseInt(saleData[x].CadFinalPlan?saleData[x].CadFinalPlan:0)
                      CadFinalPlanLength.push(saleData[x].CadFinalPlan)
                    }
                    if(saleData[x].CadExpress){
                      this.average[3].CadExpress+=parseInt(saleData[x].CadExpress?saleData[x].CadExpress:0)
                      CadExpressLength.push(saleData[x].CadExpress)
                    }

                    if(saleData[x].denki){
                    this.average[3].denki+=parseInt(saleData[x].denki?saleData[x].denki:0)
                    denkiLength.push(saleData[x].denki)
                    }
                    if(saleData[x].denkiExpress){
                    this.average[3].denkiExpress+=parseInt(saleData[x].denkiExpress?saleData[x].denkiExpress:0)
                    denkiExpressSalesLength.push(saleData[x].denkiExpress)
                    }
                    if(saleData[x].ExternalPers){
                    this.average[3].externalPers+=parseInt(saleData[x].ExternalPers?saleData[x].ExternalPers:0)
                    externalPersLength.push(saleData[x].ExternalPers)
                    }
                    if(saleData[x].House_presentation){
                    this.average[3].HP+=parseInt(saleData[x].House_presentation?saleData[x].House_presentation:0)
                    HPLength.push(saleData[x].House_presentation)
                    }
                    if(saleData[x].SI_WAKU_DUCT){
                    this.average[3].siWaku+=parseInt(saleData[x].SI_WAKU_DUCT?saleData[x].SI_WAKU_DUCT:0)
                    wakuSalesLength.push(saleData[x].SI_WAKU_DUCT)
                    }
                    if(saleData[x].SI_WAKU_DUCT == 0 && saleData[x].SI_JIKU){
                    this.average[3].siJiku+=parseInt(saleData[x].SI_JIKU ? saleData[x].SI_JIKU :0)
                    jikuSalesLength.push(saleData[x].SI_JIKU)
                    }

                    if(saleData[x].ALVS){
                    this.average[3].alvs+=parseInt(saleData[x].ALVS?saleData[x].ALVS:0)
                    alvsSalesLength.push(saleData[x].ALVS)
                    }
                  }
                    this.average[3].CadPhase1 =  this.tofixed2((this.average[3].CadPhase1 / CadPhase1Length.length))
                    this.average[3].CadPdr =  this.tofixed2((this.average[3].CadPdr / CadPdrLength.length))
                    this.average[3].CadFinalPlan =  this.tofixed2((this.average[3].CadFinalPlan / CadFinalPlanLength.length))
                    this.average[3].CadExpress =  this.tofixed2((this.average[3].CadExpress / CadExpressLength.length))

                    this.average[3].cad =  this.tofixed2((this.average[3].cad / CadLength.length))
                    this.average[3].denki =  this.tofixed2((this.average[3].denki / denkiLength.length))  
                    this.average[3].denkiExpress = this.tofixed2((this.average[3].denkiExpress / denkiExpressSalesLength.length))  
                    this.average[3].externalPers =  this.tofixed2((this.average[3].externalPers / externalPersLength.length))  
                    this.average[3].HP =  this.tofixed2((this.average[3].HP /HPLength.length))  
                    this.average[3].siWaku =  this.tofixed2((this.average[3].siWaku / wakuSalesLength.length))  
                    this.average[3].siJiku =  this.tofixed2((this.average[3].siJiku /jikuSalesLength.length)) 
                    this.average[3].alvs =  this.tofixed2((this.average[3].alvs /alvsSalesLength.length)) 


                let arrt2=[]
                  for(let x=0;x<=plann2.length-1;x++){
                let obj2={plannCode:'',CadPhase1:0,CadPdr:0,CadFinalPlan:0,CadExpress:0,cad:0,denki:0,denkiExpress:0,externalPers:0,HP:0,siWaku:0,siJiku:0,alvs:0}
                      if(plann2[x]){
                        for(let i=0;i<=plann2[x].length-1;i++){
                          obj2.plannCode=plann2[x][0].EmployeeCode
                          obj2.CadPhase1+= plann2[x][i].CadPhase1 ? parseInt(plann2[x][i].CadPhase1) : 0
                          obj2.CadPdr+=plann2[x][i].CadPdr ? parseInt(plann2[x][i].CadPdr)  : 0
                          obj2.CadFinalPlan+=plann2[x][i].CadFinalPlan ? parseInt(plann2[x][i].CadFinalPlan)  : 0
                          obj2.CadExpress+=plann2[x][i].CadExpress ? parseInt(plann2[x][i].CadExpress)  : 0
                          obj2.cad+=plann2[x][i].cad ? parseInt(plann2[x][i].cad)  : 0
                          obj2.denki+=plann2[x][i].denki ? parseInt(plann2[x][i].denki)  : 0
                          obj2.denkiExpress+=plann2[x][i].denkiExpress ? parseInt(plann2[x][i].denkiExpress)  : 0
                          obj2.externalPers+=plann2[x][i].ExternalPers ? parseInt(plann2[x][i].ExternalPers)  : 0
                          obj2.HP+=plann2[x][i].House_presentation ? parseInt(plann2[x][i].House_presentation)  : 0
                          obj2.siWaku+=plann2[x][i].SI_WAKU_DUCT ? parseInt(plann2[x][i].SI_WAKU_DUCT)  : 0
                          obj2.siJiku+=plann2[x][i].SI_JIKU ? parseInt(plann2[x][i].SI_JIKU)  : 0
                          obj2.alvs+=plann2[x][i].ALVS ? parseInt(plann2[x][i].ALVS)  : 0

                        }

                        obj2.CadPhase1 = parseFloat(this.tofixed2((obj2.CadPhase1 / plann2[x].length)))
                        obj2.CadPdr = parseFloat(this.tofixed2((obj2.CadPdr / plann2[x].length)))
                        obj2.CadFinalPlan = parseFloat(this.tofixed2((obj2.CadFinalPlan / plann2[x].length)))
                        obj2.CadExpress = parseFloat(this.tofixed2((obj2.CadExpress / plann2[x].length)))
                        obj2.cad = parseFloat(this.tofixed2((obj2.cad / plann2[x].length)))
                        obj2.denki = parseFloat(this.tofixed2((obj2.denki / plann2[x].length)))  
                        obj2.denkiExpress = parseFloat(this.tofixed2((obj2.denkiExpress / plann2[x].length)))  
                        obj2.externalPers = parseFloat(this.tofixed2((obj2.externalPers / plann2[x].length)))  
                        obj2.HP = parseFloat(this.tofixed2((obj2.HP /plann2[x].length)))  
                        obj2.siWaku = parseFloat(this.tofixed2((obj2.siWaku / plann2[x].length)))  
                        obj2.siJiku = parseFloat(this.tofixed2((obj2.siJiku /plann2[x].length)))
                        obj2.alvs = parseFloat(this.tofixed2((obj2.alvs /plann2[x].length)))
                        
                    }
                    arrt2.push(obj2)
                  }

                 
                    for(let x =0; x<= category.length -1; x++){
                  // console.log('----------------------',category[x],'-----------d2---------------')
                     this.salesLength =  _.orderBy(arrt2 ,[`${category[x]}`] ,['asc'])
                     
                      // for(let i =0 ; i <=  this.salesLength.length-1;i++){
                      //   if( this.salesLength[i].plannCode==`${this.datePicker.EmployeeCode}`){
                      //       this.average[4][`${category[x]}`]  = i+1 
                      //   }
                      // }
                        let arr=this.salesLength.reduce((total,item)=>{
                          let index =total.findIndex(y=> y.value == item[`${category[x]}`] ) 
                          if(index > -1 ){
                              total[index].data.push(item)
                            return total
                          }
                          else{
                            total.push(
                            {
                              value:item[`${category[x]}`],
                              data:[item]
                            }
                            )
                            return total
                          }
                      },[])
                        let ctr = 0 
                        arr.filter((rec,index)=>{
                            // console.log(rec)
                          rec.data.forEach((r)=>{
                            if(r.plannCode == `${this.datePicker.EmployeeCode}` && index == 0){
                              this.average[4][`${category[x]}`] = index+1
                            }
                            else if (r.plannCode == `${this.datePicker.EmployeeCode}` && index > 0){
                              this.average[4][`${category[x]}`] = ctr +1
                            }
                          })
                        ctr +=  rec.data.length
                      })
                   
                    }
                  
// National

let wakuNationalLength=[]
let jikuNationalLength=[]
let alvsNationalLength=[]
let denkiExpressNationalLength=[]
            let nationplan=overall[0].plannerCode
            let nationarr=overall[0].countDocu
// console.log(nationarr,'nationarr');
              for(let x=0;x<=nationarr.length-1;x++){
                // if(nationarr[x].cad){
                this.average[5].cad+=parseInt(nationarr[x].cad?nationarr[x].cad:0)
                this.average[5].CadPhase1+=parseInt(nationarr[x].CadPhase1?nationarr[x].CadPhase1:0)
                this.average[5].CadPdr+=parseInt(nationarr[x].CadPdr?nationarr[x].CadPdr:0)
                this.average[5].CadFinalPlan+=parseInt(nationarr[x].CadFinalPlan?nationarr[x].CadFinalPlan:0)
                this.average[5].CadExpress+=parseInt(nationarr[x].CadExpress?nationarr[x].CadExpress:0)



                // }
                // if(nationarr[x].denki){
                this.average[5].denki+=parseInt(nationarr[x].denki?nationarr[x].denki:0)
                // }
                // if(nationarr[x].ExternalPers){
                this.average[5].externalPers+=parseInt(nationarr[x].ExternalPers?nationarr[x].ExternalPers:0)
                // }
                // if(nationarr[x].House_presentation){
                this.average[5].HP+=parseInt(nationarr[x].House_presentation?nationarr[x].House_presentation:0)
                // }
                if(nationarr[x].SI_WAKU_DUCT && nationarr[x].SI_WAKU_DUCT!=0){
                this.average[5].siWaku+=parseInt(nationarr[x].SI_WAKU_DUCT?nationarr[x].SI_WAKU_DUCT:0)
                wakuNationalLength.push(nationarr[x].SI_WAKU_DUCT)
                }
                if(nationarr[x].SI_JIKU && nationarr[x].SI_JIKU !=0){
                this.average[5].siJiku+=parseInt(nationarr[x].SI_JIKU?nationarr[x].SI_JIKU:0)
                jikuNationalLength.push(nationarr[x].SI_JIKU)
                }

                if(nationarr[x].ALVS && nationarr[x].ALVS !=0){
                this.average[5].alvs+=parseInt(nationarr[x].ALVS?nationarr[x].ALVS:0)
                alvsNationalLength.push(nationarr[x].ALVS)
                }
                if(nationarr[x].denkiExpress && nationarr[x].denkiExpress !=0){
                this.average[5].denkiExpress+=parseInt(nationarr[x].denkiExpress?nationarr[x].denkiExpress:0)
                denkiExpressNationalLength.push(nationarr[x].denkiExpress)
                }
              }
           
              this.average[5].cad =  this.tofixed2((this.average[5].cad / nationarr.length))
              this.average[5].CadPhase1 =  this.tofixed2((this.average[5].CadPhase1 / nationarr.length))
              this.average[5].CadPdr =  this.tofixed2((this.average[5].CadPdr / nationarr.length))
              this.average[5].CadFinalPlan =  this.tofixed2((this.average[5].CadFinalPlan / nationarr.length))
              this.average[5].CadExpress =  this.tofixed2((this.average[5].CadExpress / nationarr.length))
              this.average[5].denki =  this.tofixed2((this.average[5].denki / nationarr.length))  
              this.average[5].denkiExpress =  this.tofixed2((this.average[5].denkiExpress / denkiExpressNationalLength.length))  
              this.average[5].externalPers =  this.tofixed2((this.average[5].externalPers / nationarr.length))  
              this.average[5].HP =  this.tofixed2((this.average[5].HP /nationarr.length))  
              this.average[5].siWaku =  this.tofixed2((this.average[5].siWaku / wakuNationalLength.length))  
              this.average[5].siJiku =  this.tofixed2((this.average[5].siJiku /jikuNationalLength.length))  
              this.average[5].alvs =  this.tofixed2((this.average[5].alvs /alvsNationalLength.length))  

              // console.log(this.average[5], '[5]') 

                let nationArt=[]
                  for(let x=0;x<=nationplan.length-1;x++){
                    let obj={plannCode:'',CadPhase1:0,CadPdr:0,CadFinalPlan:0,CadExpress:0,cad:0,denki:0,denkiExpress:0,externalPers:0,HP:0,siWaku:0,siJiku:0,alvs:0}
                      if(nationplan[x]){
                        for(let i=0;i<=nationplan[x].length-1;i++){
                          obj.plannCode=nationplan[x][0].EmployeeCode
                          
                          obj.CadPhase1+=parseInt(nationplan[x][i].CadPhase1)
                          obj.CadPdr+=parseInt(nationplan[x][i].CadPdr)
                          obj.CadFinalPlan+=parseInt(nationplan[x][i].CadFinalPlan)
                          obj.CadExpress+=parseInt(nationplan[x][i].CadExpress)
                          obj.cad+=parseInt(nationplan[x][i].cad)
                          obj.denki+=parseInt(nationplan[x][i].denki)
                          obj.denkiExpress+=parseInt(nationplan[x][i].denkiExpress)
                          obj.externalPers+=parseInt(nationplan[x][i].ExternalPers)
                          obj.HP+=parseInt(nationplan[x][i].House_presentation)
                          obj.siWaku+=parseInt(nationplan[x][i].SI_WAKU_DUCT)
                          obj.siJiku+=parseInt(nationplan[x][i].SI_JIKU)
                          obj.alvs+=parseInt(nationplan[x][i].ALVS)

                        }
                        
                        obj.CadPhase1 = parseFloat(this.tofixed2((obj.CadPhase1 / nationplan[x].length)))
                        obj.CadPdr = parseFloat(this.tofixed2((obj.CadPdr / nationplan[x].length)))
                        obj.CadFinalPlan = parseFloat(this.tofixed2((obj.CadFinalPlan / nationplan[x].length)))
                        obj.CadExpress = parseFloat(this.tofixed2((obj.CadExpress / nationplan[x].length)))
                        obj.cad = parseFloat(this.tofixed2((obj.cad / nationplan[x].length)))
                        obj.denki = parseFloat(this.tofixed2((obj.denki / nationplan[x].length)))
                        obj.denkiExpress = parseFloat(this.tofixed2((obj.denkiExpress / nationplan[x].length)))
                        obj.externalPers = parseFloat(this.tofixed2((obj.externalPers / nationplan[x].length)))
                        obj.HP = parseFloat(this.tofixed2((obj.HP /nationplan[x].length)))
                        obj.siWaku = parseFloat(this.tofixed2((obj.siWaku / nationplan[x].length)))  
                        obj.siJiku = parseFloat(this.tofixed2((obj.siJiku /nationplan[x].length)) )
                        obj.alvs = parseFloat(this.tofixed2((obj.alvs /nationplan[x].length)) )

                    }
                    nationArt.push(obj)
                    
                  }
                for(let x =0; x<= category.length -1; x++){
                  // console.log('----------------------',category[x],'--------------------------')
                  this.NationLength=  _.orderBy(nationArt ,[`${category[x]}`] ,['asc'])
                  let arr=this.NationLength.reduce((total,item)=>{
                      let index =total.findIndex(y=> y.value == item[`${category[x]}`] ) 
                      if(index > -1 ){
                          total[index].data.push(item)
                        return total
                      }
                      else{
                        total.push(
                         {
                          value:item[`${category[x]}`],
                          data:[item]
                         }
                        )
                        return total
                      }
                  },[])
                  let ctr = 0 
                  arr.filter((rec,index)=>{
                    rec.data.forEach((r)=>{
                      if(r.plannCode == `${this.datePicker.EmployeeCode}` && index == 0){
                        this.average[6][`${category[x]}`] = index+1
                      }
                      else if (r.plannCode == `${this.datePicker.EmployeeCode}` && index > 0){
                        this.average[6][`${category[x]}`] = ctr +1
                      }
                    })
                  ctr +=  rec.data.length
                })
              }
              // console.log(this.average);
            } 
            // })//res2.data
          })//res.data
          // this.startingTableHeight()
          // this.updateHeight()
          this.$nextTick(() => {
            this.plannerData.getTableHeight()
            this.setSecondTheadTop()
            this.plannerData.getColumnWidth(2, this.getColumnCount())
          });
            }else{
              Swal.fire({
                icon: "warning",
                  // title: "Oops...",
                  title: `${this.$i18n.locale =='ja'?'エラー' :'Warning' }`,
                  text: `${this.$i18n.locale =='ja'?' 開始日から終了日までの期間が1年以内の期間になるように入力し直してください。' :'Please re-enter so that the period from the start date to the end date is within one year.!'}`,
                });
                this.loading=false;
                return false
            }
          }catch (error) {
            this.loading=false;
            alert('Error fetching data:', error);
          }
        },
        getPlannerCode() {
          if( parseInt(moment.duration(moment(this.plannerCodeRange.toDate,"YYYY-MM-DD").diff(moment(this.plannerCodeRange.fromDate,"YYYY-MM-DD"))).asDays()) <= 365   ){
            this.user={}
            this.user=this.userInfo  
            if(this.userInfo.User_Category != 'admin' && this.userInfo.User_Category != 'GC Admin'){
              this.planners=[]
              this.plannerCodeRange.PlannerCode=this.userInfo.Emp_code
              let obj={
                PlannerCode:this.userInfo.Emp_code,
                fromDate:this.plannerCodeRange.fromDate,
                toDate:this.plannerCodeRange.toDate
              }
              this.plannersLoading=true
              this.plannersCodefunction.getplannersCode(this.aws,obj,this.apiKey).then((r)=>{
                this.planners=r.data
                let a = this.planners.findIndex(data=> data.EmployeeCode == obj.PlannerCode)
                  if(a == -1){
                    Swal.fire({
                      icon: "error",
                      title: "Error!",
                      text: `Planner ${this.userInfo.Emp_code} dont have Data`,
                    });
                    this.planners.push({
                      EmployeesData:`${this.userInfo.Emp_code +' - '+this.userInfo.Name}`,
                      EmployeeCode:this.userInfo.Emp_code,
                      EmployeeName:this.userInfo.Name
                    })
                  }
                this.plannersLoading=r.loading
              }) 
            }else{
              this.SalesOfficeArr=[]
              this.planners=[]
              this.SalesLoading=true;
              this.SO=''
              let obj={
                fromDate:this.plannerCodeRange.fromDate,
                toDate:this.plannerCodeRange.toDate,
                userCategory:this.userInfo.User_Category,
                SalesOfficeName:this.userInfo.User_Category
              }
              this.salesOfficefunction.getSalesOffice(obj,this.aws,this.apiKey).then((r)=>{
                    this.SalesOfficeArr = r.data.filter((x) => x.EmployeeName !== null).sort((a, b) => a.sort - b.sort);
                    this.SalesLoading = r.data.loading;
              })
            }
          }else{
            Swal.fire({
              icon: "warning",
              title: `${this.$i18n.locale =='ja'?'エラー' :'Warning' }`,
              text: `${this.$i18n.locale =='ja'?' 開始日から終了日までの期間が1年以内の期間になるように入力し直してください。' :'Please re-enter so that the period from the start date to the end date is within one year.!'}`,
            });
            return false
          }
        },
          getPlannerData(){
              this.planners=[]
              this.cadData=[]
              this.plannerCodeRange.Planner.EmployeesData=''
              this.planners = this.SalesOfficeArr.filter(r=>{
                if( r.SalesOfficeName == this.SO.SalesOfficeName){
                    r.EmployeesData = `${r.EmployeeCode +' - ' +r.EmployeeName}`
                  return r
                }
              })
          },
          splitChar(data) {
            if (data.includes('(')) {
              const parts = data.split('(');
              return [parts[0], '(' + parts.slice(1).join('(')];
            } else {
              return data;
            }
          },
          copyText(textToCopy, index) {
          this.$set(this.showText, index, true);

          const constructionCodeElement = this.$el.querySelector(`#constructionCode-${index}`);
          constructionCodeElement.classList.add('simulate-text-highlight');

          // Remove the highlight for the last copied index (if any)
          if (this.lastCopiedIndex !== null) {
            const lastCopiedElement = this.$el.querySelector(`#constructionCode-${this.lastCopiedIndex}`);
            lastCopiedElement.classList.remove('simulate-text-highlight');
          }

          setTimeout(() => {
            this.$set(this.showText, index, false);
            constructionCodeElement.classList.remove('simulate-text-highlight');
          }, 800);

          const textarea = document.createElement('textarea');
          textarea.value = textToCopy;
          document.body.appendChild(textarea);
          textarea.select();
          document.execCommand('copy');
          document.body.removeChild(textarea);

          // Update the last copied index
          this.lastCopiedIndex = index;
        },
        hoverColumn(val, index){
          this.$set(this.copyIcon, index, val);
        },
        setSecondTheadTop() {
          this.$nextTick(() => {
            if(this.$refs.firstThead){
              const firstTheadRect = this.$refs.firstThead.getBoundingClientRect();
                const firstTheadHeight = firstTheadRect.height;
                this.secondTheadTop = firstTheadHeight;
            }
          })
        },
        getColumnCount(){
          return 10
        }
      },
      mounted() {
        this.getPlannerCode()
        window.addEventListener('resize', () =>{
          this.plannerData.getTableHeight()
          this.setSecondTheadTop()
          this.plannerData.getColumnWidth(2, this.getColumnCount())
        })
      },
      computed: {
          monthRestriction() {
              return moment(this.plannerCodeRange.fromDate).add(12,'months').endOf('month').format('YYYY-MM-DD')
          },
          userInfo() {
            return this.$root.decryptedUser;
          }
      },
      watch: {
        '$i18n.locale'() {
          if (this.cadData.length !== 0) {
            this.setSecondTheadTop()
          }
        },
      },
  }
</script>

<style>
::selection {
  background-color: #3399ff;  /* Change the background color to simulate the Windows highlight effect */
  color: white;  /* Change the text color for better contrast */
}
.sticky-thead {
  position: sticky;
  z-index: 1;
  background-color: white;
}
.simulate-text-highlight {
  background-color: #3399ff;  /* Change the background color to simulate the Windows highlight effect */
  color: white;  /* Change the text color for better contrast */
}
.TableStyle {
  border: 1px solid black;
}
.last-row td{
  background: white;
}
.center {
position: absolute;
top: 50%;
width: 100%;
text-align: center;
}
.backColor {
background-color: rgb(163, 147, 147);
}

.cnter{
  position:absolute;
  width: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.header {
text-wrap:wrap;
background-color: #01579b; 
color: white !important;
}
</style>
