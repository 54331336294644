export default class PlannerData
{
  constructor()
  {
    this.toDecrease = 0.03
    this.tableHeight = ''
    this.columnWidth = {}
    this.distanceFromTop = ''
    this.intervalOfDates = {}
    this.scroll = ''
  }
  
  

  getColumnWidth(columnCount1, columnCount2) 
  {
    const simpleTable = document.querySelector('#tableRef')

    if(this.scrollExist()){
      if(simpleTable){
        const total = simpleTable.offsetWidth - 17
        const column1 = total * 0.70 / columnCount2
        const column2 = total * 0.30 / columnCount1
        const width1 = String(column1);
        const width2 = String(column2);
        this.columnWidth.width1 = `${width1}px`;
        this.columnWidth.width2 = `${width2}px`;
      }
    }else{
      if(simpleTable){
        const total = simpleTable.offsetWidth
        const column1 = total * 0.70 / columnCount2
        const column2 = total * 0.30 / columnCount1
        const width1 = String(column1);
        const width2 = String(column2);
        this.columnWidth.width1 = `${width1}px`;  
        this.columnWidth.width2 = `${width2}px`;
      }
    }
  }

  scrollExist() {
    const tableRef = document.getElementById('tableRef');
    if(tableRef){
      const theadElements = tableRef.querySelectorAll('thead');
      const tbodyElements = tableRef.querySelectorAll('tbody');
      const theadArray = Array.from(theadElements);
      const tbodyArray = Array.from(tbodyElements);
    if (theadArray.length > 0 && tbodyArray.length > 0) {
      let totalTheadHeight = 0;
      let totalTbodyHeight = 0;
  
      theadArray.forEach((thead) => {
        const theadRect = thead.getBoundingClientRect();
        const theadHeight = theadRect.height; 
        totalTheadHeight += theadHeight;
      });
  
      tbodyArray.forEach((tbody) => {
        const tbodyRect = tbody.getBoundingClientRect();
        const tbodyHeight = tbodyRect.height; 
        totalTbodyHeight += tbodyHeight;
      });
  
      const totalHeight = totalTheadHeight + totalTbodyHeight;
      this.scroll = totalHeight > this.tableHeight;
      // console.log(totalHeight,'totalHeight');
      // console.log(this.tableHeight,'tableHeight');
      // console.log(this.scroll,'scroll');
      return this.scroll
    }
    return false; 
    }
  }

  getTableHeight()
  {
    const simpleTable = document.querySelector('#tableRef');
    if (simpleTable)
    {
      this.distanceFromTop = simpleTable.getBoundingClientRect().top;
      const tableHeightToBottom = window.innerHeight - this.distanceFromTop
      this.tableHeight = tableHeightToBottom - (tableHeightToBottom * this.toDecrease);
      // console.log(this.distanceFromTop, 'top distance');
      // console.log(this.tableHeight,'height');
    }
  }


  getMonths(from, to)
  {
    const fromDate = new Date(from);
    const toDate = new Date(to);
    
    const months = [];

    let currentMonth = fromDate.getMonth() + 1;
    let currentYear = fromDate.getFullYear();

    while (currentYear < toDate.getFullYear() || (currentYear == toDate.getFullYear() && currentMonth <= toDate.getMonth() + 1)) {
      months.push({ year: currentYear, month: currentMonth });

        currentMonth++;
        if (currentMonth >= 13) {
            currentMonth = 1;
            currentYear++;
        }
    }
    if(months.length != 0){
      months[0].day = fromDate;
      months[months.length -1].day = toDate;
    }
    this.intervalOfDates.monthly = months
    this.getQuarterly(this.intervalOfDates)
  }


  getQuarterly(intervalOfDates)
  {
    const quarterlyStartDate = new Date(intervalOfDates.monthly[0].day);
    const quarterlyEndDate = new Date(intervalOfDates.monthly[intervalOfDates.monthly.length-1].day);

    const quarterlyDateRanges = [];

    while (quarterlyStartDate < quarterlyEndDate) {
        const startOfQuarter = new Date(quarterlyStartDate.toISOString().slice(0, 10));
        const endOfQuarter = new Date(quarterlyStartDate.getFullYear(), quarterlyStartDate.getMonth()+3, quarterlyStartDate.getDate()+2)

        quarterlyDateRanges.push({
            start: startOfQuarter.toISOString().slice(0, 10),
            end: endOfQuarter.toISOString().slice(0, 10),
        });

        if (quarterlyDateRanges.length > 1) {
          quarterlyDateRanges[quarterlyDateRanges.length - 1].start = new Date(quarterlyStartDate.getFullYear(), quarterlyStartDate.getMonth(),quarterlyStartDate.getDate()+1).toISOString().slice(0, 10);
          quarterlyDateRanges[quarterlyDateRanges.length - 2].end = new Date(quarterlyStartDate.getFullYear(), quarterlyStartDate.getMonth(), quarterlyStartDate.getDate()).toISOString().slice(0, 10);
        }

        if (quarterlyDateRanges.length -1) {
          quarterlyDateRanges[quarterlyDateRanges.length - 1].end = new Date(intervalOfDates.monthly[intervalOfDates.monthly.length-1].day).toISOString().slice(0, 10);
        }
        quarterlyStartDate.setMonth(quarterlyStartDate.getMonth() + 3);
    }
    while (quarterlyDateRanges.length < 4) {
      quarterlyDateRanges.push({ start: null, end: null});
    }
    this.intervalOfDates.quarterly = quarterlyDateRanges;
    this.getHalfYear(this.intervalOfDates)
  }

  getHalfYear(intervalOfDates)
  {
    const HalfyearStartDate = new Date(intervalOfDates.monthly[0].day);
      const HalfyearEndDate = new Date(intervalOfDates.monthly[intervalOfDates.monthly.length-1].day);

      const HalfyearDateRanges = [];

      while (HalfyearStartDate < HalfyearEndDate) {
          const startOfHalfyear = new Date(HalfyearStartDate.toISOString().slice(0, 10));
          const endOfHalfyear = new Date(HalfyearStartDate.getFullYear(), HalfyearStartDate.getMonth()+3, HalfyearStartDate.getDate()+2)

          HalfyearDateRanges.push({
              start: startOfHalfyear.toISOString().slice(0, 10),
              end: endOfHalfyear.toISOString().slice(0, 10),
          });

          if (HalfyearDateRanges.length > 1) {
            HalfyearDateRanges[HalfyearDateRanges.length - 1].start = new Date(HalfyearStartDate.getFullYear(), HalfyearStartDate.getMonth(),HalfyearStartDate.getDate()+1).toISOString().slice(0, 10);
            HalfyearDateRanges[HalfyearDateRanges.length - 2].end = new Date(HalfyearStartDate.getFullYear(), HalfyearStartDate.getMonth(), HalfyearStartDate.getDate()).toISOString().slice(0, 10);
          }

          if (HalfyearDateRanges.length -1) {
            HalfyearDateRanges[HalfyearDateRanges.length - 1].end = new Date(intervalOfDates.monthly[intervalOfDates.monthly.length-1].day).toISOString().slice(0, 10);
          }
          HalfyearStartDate.setMonth(HalfyearStartDate.getMonth() + 6);
      }
      while (HalfyearDateRanges.length < 2) {
        HalfyearDateRanges.push({ start: null, end: null});
      }
      this.intervalOfDates.halfyear = HalfyearDateRanges;
      console.log(this.intervalOfDates);
  }
}