import axios from 'axios'
export default class MERGING{
    constructor( ){
    
        // retries = 5, delay = 1000
    }   
    MergeFnc(aws, data, apiKey) {
        return new Promise((resolve) => {
            const fnc=async()=>{
                let arr =[]
                let count = await axios.post(`${aws}getCadCount`, data, apiKey)
                let newValue=this.chunkArray(count.data[0].Total)
                let from=1 
                let to=newValue[0]
                for (let i = 0; i < newValue.length; i++) {
                    await axios.post(`${aws}getAllDataCad/${from}/${to}`, data, apiKey).then(async(res)=>{
                        if(res.data){
                             arr.push( await res.data)
                        }
                    }).catch(err=>{
                        if(err)fnc();
                    })
                    from = newValue.length-1 == i+1 ?  from + newValue[i] : from + newValue[i+1]
                    to = to + newValue[i+1]
                }
                Promise.all(arr).then(val=>{
                   let output={data:val.flat()}
                    resolve(output)
                })
            }
            fnc()
            // const makeRequest = (retriesLeft) => {
            //     axios.all([
            //         axios.post(`${aws}getCadDetails`, data, apiKey),
            //         axios.post(`${aws}getDaysData`, data, apiKey),
            //         axios.post(`${aws}getCadRequest`, data, apiKey)
            //     ])
            //     .then(axios.spread((res3, res, res2) => {
            //         res2.data = res2.data.filter(x => {
            //             let index = res.data.findIndex(y => x.ConstructionCode == y.ConstructionCode && x.EmployeeCode == y.EmployeeCode && x.HouseType == y.HouseType)
            //             let index2 = res3.data.findIndex(z => x.ConstructionCode == z.ConstructionCode && x.EmployeeCode == z.EmployeeCode && x.HouseType == z.HouseType)
            //             if (index > -1 && index2 > -1) {
            //                 x.JoutouDays = res.data[index].JoutouDays || '';
            //                 x.decidedlandDays = res.data[index].decidedlandDays || '';
            //                 x.finalPlan = res.data[index].finalPlan || '';
            //                 x.stopcheck = res.data[index].stopcheck || '';
            //                 x.KakouDays = res.data[index].KakouDays || '';
            //                 x.CadPhase1 = res3.data[index2].CadPhase1 || '';
            //                 x.CadFinalPlan = res3.data[index2].CadFinalPlan || '';
            //                 x.CadPdr = res3.data[index2].CadPdr > 0 ? res3.data[index2].CadPdr : res3.data[index2].CadDenkiPlan || '';
            //                 x.CadExpress = res3.data[index2].CadExpress || '';
            //                 x.CadContract = res3.data[index2].CadContract || '';
            //                 return x;
            //             }
            //         });
            //         resolve(res2);
            //     }))
            //     .catch(err => {
            //         if (err.response && err.response.status === 502) {
            //             return;
            //         }
            //         if (retriesLeft === 0) {
            //             reject(err);
            //             return;
            //         }
            //         setTimeout(() => {
            //             makeRequest(retriesLeft - 1);
            //         }, delay);
            //     });
            // };
            // makeRequest(retries);
        });
    }
     chunkArray(value) {
        const chunkSize = 1000;
        const chunks = [];
        while (value > 0) {
            if (value >= chunkSize) {
                chunks.push(chunkSize);
                value -= chunkSize;
            } else {
                chunks.push(value);
                value = 0;
            }
        }
        return chunks;
    }
    
}